.video-view {
    position: relative;
    margin: 0 auto;
}

.video-view > video {
    position: absolute;
    object-fit: contain;
    background-color: gray;
}

.video-view > .video-controls {
    position: absolute;
    width: 100%;
    align-self: center;
    bottom: 0.5rem;
}

.video-view > .video-helper-text {
    position: absolute;
    width: 100%;
    top: 0.5rem;
    text-align: center;
    color: white;
}

.video-view > .video-helper-img {
    position: absolute;
    top: 2.5rem;
    right: 0.5rem;
    max-width: 25%;
    max-height: 25%;
}

.video-view > .video-helper-img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid lightgray;
    border-radius: 10%;
}

.video-view > .video-helper-img > p {
    position: relative;
    width: 100%;
    color: white;
    text-align: center;
}