@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Style for the spinner image */
.custom-spinner .spinner-image {
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}