img.logo {
    width: auto;
    height: 2.5rem;
    object-fit: contain;
    vertical-align: middle;
}

.navbar-icon {
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: middle;
}
