@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.out-of-stock {
    position: relative;
}

// .out-of-stock::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(128, 128, 128, 0.5);
//     /* Gray shading */
//     z-index: 1;
// }

.out-of-stock img {
    filter: grayscale(100%) opacity(0.5) blur(1px);
}

.out-of-stock::after {
    content: attr(data-out-of-stock-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #dc3545;
    /* Red badge background */
    color: white;
    opacity: 0.7;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
    z-index: 2;
}

.page-video {
    width: 320px;
    height: 240px;
}

@include media-breakpoint-up(md) {
    .page-video {
        width: 480px;
        height: 360px;
    }
}

@include media-breakpoint-up(lg) {
    .page-video {
        width: 640px;
        height: 480px;
    }
}

@include media-breakpoint-up(xl) {
    .page-video {
        width: 960px;
        height: 720px;
    }
}