body, html {
    background-color: white;
}

/*img*/.shoe-card {
    width: 100%;
    height: 15rem;
    object-fit: contain;
}

/*img*/.shoe-details {
    width: 100%;
    height: 500px;
    object-fit: contain;
}

img.meas-card {
    width: 100%;
    height: 20rem;
    object-fit: contain;
}

img.meas-details {
    width: 100%;
    height: 500px;
    object-fit: contain;
}

img.img-inline {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 1.5rem;
}

.text-excellent {
    color: #009f45;
}

.text-good {
    color: #E3BA26;
}

.text-acceptable {
    color: #D87F1C;
}

.text-bad {
    color: #E32636;
}

.keep-newlines {
    white-space: pre-line;
}

.container {
    max-width: 1600px;
    /* Adjust this value to your desired max width */
    padding-left: 80px;
    /* Adjust this value to control the left/right blank fields */
    padding-right: 80px;
}

@media (max-width: 1600px) {
    .container {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}
