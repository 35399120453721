$grey-stripe-dark: #f6f6f6;
$grey-stripe-light: #f9f9f9;

.bg-construction {
    background: repeating-linear-gradient(45deg,
            $grey-stripe-dark,
            $grey-stripe-dark 10px,
            $grey-stripe-light 10px,
            $grey-stripe-light 20px);
}

.border-construction {
    border: 1px dashed $grey-stripe-dark;
}