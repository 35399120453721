.position-relative {
    position: relative;
}

.overlay-checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1; /* Ensure it appears above the image */
    padding: 3px;
    // border-radius: 20%;
    // background-color: white;
    // border: 1px solid #ccc;
    cursor: pointer;
}
